import React from "react"
import styled from "styled-components"
import { MdPhoneIphone } from "react-icons/md"
import { IconContext } from "react-icons";

const Footer = ( { className }) => {
    return (
        <footer className={className}>
            <div className="address">
                <p className="name">Marzena Dudek</p>
                <p className="title">Terapeuta metody Bowena</p>
                ul. Legionów 83<br></br>
                43-502 Czechowice Dziedzice<br></br>
                tel. 692-351-304
            </div>
            <div className="contact">
                
                <IconContext.Provider value={{ size: "4em", className: "phone-icon" }}>
                    <MdPhoneIphone />
                </IconContext.Provider>
                
                <div className="contact-info">
                    <p>692-351-304 </p>
                    <p>kontakt@bowenbeskidy.pl</p>
                </div>
            </div>
        </footer>
    )
}

export default styled(Footer)`

    background: var(--mainYellow);
    color: var(--mainWhite);
    padding: 2rem 2rem;

    .name {
        font-weight: bold;
        font-size: 1.1rem;
    }

    .contact {
            display: flex;
            align-items: center;
            margin: 0;
            padding: 0;
        }

    .phone-icon {
        margin: 1rem 0 1rem -1rem;
        min-height: 50px;
        min-width: 50px;
    }

    .contact-info {
            width: 100%;
            line-height: 1.2;       
            font-size: 1.2rem;
            font-weight: bold;
    }

    @media(min-width: 40rem) {
    
        padding: 2rem 8rem;
        display: flex;
        align-items: center;
        div {
            width: 50%;
        }
        p {
            margin: 0;
        }
        
        .title {
            margin-bottom: 1rem;
        }
        
        .phone-icon {
            margin: 1rem;
            min-height: 64px;
            min-width: 64px;
        }
        .contact-info {
            display: flex;
            flex-direction: column;
            width: 100%;
            line-height: 1.2;       
            font-size: 2rem;
            font-weight: bold;
        }
    }
`