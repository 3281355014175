import React, { useState } from 'react'
import styled from 'styled-components'
import { MdFormatAlignRight } from 'react-icons/md'
import { Link } from 'gatsby'

const Navbar = ({ className }) => {
    const [isOpen, setNav] = useState()
    const toggleNav = () => {
        setNav(isOpen => !isOpen)
    }
    return (
        <nav className={className}>
            <div className="nav-center">
            <div className="nav-header">
                    <button type="button" className="logo-btn" onClick={toggleNav}>
                        <MdFormatAlignRight className="logo-icon" />
                    </button>
                </div>
                <ul className={
                    isOpen
                        ? `${"nav-links"} ${"show-nav"}`
                        : `${"nav-links"}`
                    }
                    >
                    <li>
                        <Link to="czym-jest">O metodzie Bowena</Link>
                    </li>
                    <li>
                        <Link to="korzysci">Zalety metody Bowena</Link>
                    </li>
                    <li>
                        <Link to="o-mnie">O mnie</Link>
                    </li>
                </ul>
                
            </div>
        </nav>
    )
}

export default styled(Navbar)`
    
    background: var(--navbarColor);

    .nav-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        
    }

    .logo-icon {
        color: var(--primaryColor);
        font-size: 2rem;
    }
    .nav-links {
        list-style-type: none;
        transition: var(--mainTransition);
        height: 0;
        overflow: hidden;
    }

    .show-nav {
        display: inline-block;
        height: 120px;
    }

    .nav-links a {
        display: block;
        padding: 0.5rem 1.25rem;
        text-decoration: none;   
        color: var(--mainWhite);
        transition: var(--mainTransition);
        letter-spacing: var(--mainSpacing);
    }
    .nav-links a:hover {
        color: var(--primaryColor);
    }

    .logo-btn {
        position: absolute;
        top: 30px;
        right: 5px;
        color: var(--primaryColor);
    }

    @media(min-width: 40rem) {
        
        .nav-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 1rem 1.25rem;
        }
        .logo-btn {
            background: transparent;
            border: none;
            outline: none;
        }
        .logo-btn:hover {
            cursor: pointer;
        }
        .logo-icon {
            color: var(--mainWhite);
            font-size: 1.5rem;
        }
        .nav-links {
            list-style-type: none;
            transition: var(--mainTransition);
            
            overflow: hidden;
        }
        .show-nav {
            height: 216px;
        }
        .nav-links a {
            padding: 1.5rem 1.25rem;
        }
        .nav-links a:hover {
            color: var(--primaryColor);
        }
        .nav-social-links {
            display: none;
        }
        @media screen and (min-width: 576px) {
            .navbar {
                padding: 0 2rem;
            }
        }
        @media screen and (min-width: 40rem) {
            .nav-header {
                display: none;
            }
            .nav-center {
                max-width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
            }
            .nav-links {
                height: auto;
                display: flex;
            }
        }
    }  
`