import { Link } from "gatsby"
import React from "react"
import styled from 'styled-components'
import img from "../images/logo.png"

const Header = ({ className }) => (
  <header className={className}>
    <Link to="/">
      <div>
        <img src={img} alt="logo" />
        <h1>
          <span className="green">Reha</span>-Fit Marzena Dudek
        </h1>
        <h2>
          TERAPIA METODĄ BOWENA
        </h2>
      </div>
    </Link>
  </header>
)

export default styled(Header)`
  margin-top: 1.5rem;
  text-align: center;
  a {
    text-decoration: none;
  }
  div {
    color: var(--mainGrey);
    text-transform: uppercase;
  }
  h1 {
    margin 0;
    font-size: 1.5rem;
  }
  h2 {
    font-weight: normal;
    margin 0;
    font-size: 1rem;
  }
  img {
    margin-bottom: 0.5rem;
    max-height: 75px;
  }
  .green {
    color: 	var(--primaryColor);
  }

  @media(min-width: 40rem) {
    display: flex;
    justify-content: center;

    div {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    h1 {
      font-size: 2.5em;
    }

    img {
      margin-bottom: 0.5rem;
      max-height: 90px;
    }
  }
`